import moment from 'moment'
import { v4 } from 'uuid'
import { fileStatus } from '../../components/Recommendations/Campaign/Media/media'
import constants from '../../constants'
import { AssetClass, Platform } from './integrations'
import { TARGETING_MODES } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import { isObjectEmpty } from 'utils/helpers'

export const CampaignStatus = Object.freeze({
  running: 'running',
  open: 'open',
  pending: 'pending',
  archived: 'archived',
})

export const CampaignFrontendStatus = Object.freeze({
  default: 'DEFAULT',
  flagged: 'FLAGGED',
  hidden: 'HIDDEN',
  cancelled: 'CANCELLED',
})

export const CampaignGoal = Object.freeze({
  utilization: 'UTILIZATION',
  roas: 'ROAS',
  visibility: 'VISIBILITY',
})

export const IntegrationPlatform = Object.freeze({
  facebook: 'facebook',
  instagram: 'instagram',
})

export const IntegrationAssetClass = Object.freeze({
  profile: 'profile',
  pixel: 'pixel',
  adAccount: 'ad_account',
})

export const DEFAULT_CAMPAIGN_GOAL = CampaignGoal.utilization
export const DEFAULT_CAMPAIGN_BUDGET = 1500

export function filterByStatus(campaignsList, status) {
  return campaignsList.filter((x) => x.status === status)
}

export function hasRunningCampaigns(campaignsList) {
  return campaignsList.some((x) => x.status === CampaignStatus.running)
}

export function tcDisplayName(campaign) {
  return campaign.tc.replace('-', ' ')
}

export function isCampaignRunning(campaign, { validateNotEnded } = { validateNotEnded: false }) {
  return campaign.status === CampaignStatus.running && (!validateNotEnded || !isCampaignEnded(campaign))
}

export function isCampaignOpen(campaign) {
  return campaign.status === CampaignStatus.open
}

export function isCampaignPublished(campaign) {
  return !!campaign.published
}

export function isCampaignEnded(campaign) {
  return moment.utc() > moment.utc(campaign.fb_adset_end_time)
}

export function rankCampaignList(campaigns) {
  return campaigns.sort((a, b) => [a.rank - b.rank])
}

export function campaignKeywords(campaign) {
  if (!campaign.creativeSuggestion) return { headline: [], body: [], media: [], description: undefined }

  return {
    headline: campaign.creativeSuggestion.headline,
    body: campaign.creativeSuggestion.body,
    media: campaign.creativeSuggestion.media,
    description: campaign.creativeSuggestion.description,
  }
}

export function augmentIncomingMedia(media) {
  return (
    media?.map((x, index) => ({
      ...x,
      id: x.id || v4(),
      status: fileStatus.success,
      index,
    })) ?? []
  )
}

export function buildSetupPayload(
  {
    total_budget = null,
    goal,
    creatives = null,
    targeting = [],
    conversion_id = undefined,
    custom_event_type = undefined,
    integration_details = null,
    start_date = null,
    end_date = null,
  },
  options = { setupOnly: true, defaultCreatives: {}, disabledVariations: null, setDefaultTargeting: false },
) {
  if (![total_budget, goal].every(Boolean))
    throw new Error('Setup payload cannot be empty. Budget and goal are required.')

  if (!options.setupOnly && ![creatives, integration_details].every(Array.isArray)) {
    throw new Error('A valid setup requires valid creatives and integrations arrays.')
  }

  if (Array.isArray(creatives)) {
    const invalidCreatives = creatives.filter((x) => validateCreative(x, options).valid !== true)

    if (invalidCreatives.length) {
      console.error('Invalid creatives:', invalidCreatives)
      throw new Error('Invalid creatives')
    }
  }

  // Workaround an api issue, goal is being returned like this 'Goal.Utilization' instead of 'Utilization'
  const goalParts = goal.split('.')
  const result = {
    total_budget,
    start_date,
    end_date,
    conversion_id: conversion_id ?? '',
    custom_event_type: custom_event_type ?? '',
    goal: goalParts[goalParts.length - 1],
  }

  result.targeting = prepareTargetingPayload(targeting, creatives, options.setDefaultTargeting)

  if (creatives && Array.isArray(creatives)) {
    result.creatives = creatives.map((x) =>
      prepareCreativePayload({
        ...x,
        excluded_variations: options.disabledVariations ? options.disabledVariations[x.tc] : x.excluded_variations,
        defaults: options.defaultCreatives,
      }),
    )
  }

  result.integration_details = prepareIntegrationPayload(integration_details)

  return result
}

export function prepareIntegrationPayload(integrations) {
  if (!Array.isArray(integrations)) return []

  return integrations.map((x) => {
    const integration = {
      asset_id: x.asset_id,
      asset_class: x.asset_class,
      asset_label: x.asset_label,
      partner_id: x.partner_id,
      platform: x.platform,
      id: x.id,
    }

    if (x.asset_class === AssetClass.ad_account) {
      integration.currency = x.currency
    }

    return integration
  })
}

export function prepareTargetingPayload(targeting, creatives, setDefaultTargeting = false) {
  const mappedTargeting = []
  for (const tcTargeting of targeting) {
    if (!tcTargeting.tc || typeof tcTargeting.tc_run_id === 'undefined') {
      continue
    }

    let {
      targetingMode = TARGETING_MODES.fdTargeting,
      postalCodes: { countries = {} } = {},
      rangeLocations: { included = [], excluded = [] } = {},
      tc,
      tc_run_id,
    } = tcTargeting
    const out = { tc, tc_run_id, targeting_mode: targetingMode }

    if ([TARGETING_MODES.postalCodes, TARGETING_MODES.rangeLocations].includes(targetingMode)) {
      const mappedCountries = Object.keys(countries).reduce((acc, key) => {
        const country = countries[key]
        if (!country || !country.length) return acc

        acc[key] = countries[key].map((x) => ({ key: x.key, name: x.name, type: x.type }))
        return acc
      }, {})

      // remove mile_range and kilometer_range from locations if not set
      included = included?.map((x) => (x.mile_range || x.kilometer_range ? x : { location: x.location }))
      excluded = excluded?.map((x) => (x.mile_range || x.kilometer_range ? x : { location: x.location }))

      const targeting = {
        ...out,
        location_recency: ['home', 'recent'], // FIXME: The only value supported by Meta now.
        'range-locations': { included, excluded },
      }

      if (mappedCountries) {
        targeting['postal-codes'] = { countries: mappedCountries }
      }

      mappedTargeting.push(targeting)
    } else if (targetingMode === TARGETING_MODES.fdTargeting) {
      mappedTargeting.push(out)
    }
  }

  if (setDefaultTargeting) {
    const defaultTargeting = mappedTargeting[mappedTargeting.length - 1]
    if (defaultTargeting) {
      for (const creative of creatives) {
        if (!mappedTargeting.some((x) => x.tc === creative.tc)) {
          mappedTargeting.push({
            ...defaultTargeting,
            tc: creative.tc,
            tc_run_id: creative.tc_run_id,
          })
        }
      }
    }
  }

  // Backend adds empty range-locations objects by default, but doesn't accept them...
  for (const targeting of mappedTargeting) {
    if (
      isObjectEmpty(targeting['range-locations']?.included) &&
      isObjectEmpty(targeting['range-locations']?.excluded)
    ) {
      delete targeting['range-locations']
    }
  }

  return mappedTargeting
}

export function prepareCreativePayload({
  tc,
  tc_run_id,
  headline,
  body,
  media,
  destination_url,
  call_to_action,
  description,
  audience_id,
  excluded_variations,
  language,
  defaults = {},
}) {
  return {
    tc,
    tc_run_id: tc_run_id,
    headline: headline?.filter(Boolean) ?? defaults.headline,
    body: body?.filter(Boolean) ?? defaults.body,
    media:
      media
        ?.filter((file) => file.status === fileStatus.success)
        .map((file) => {
          const media = {
            type: file.type,
            url: file.url,
            filename: file.name || file.filename || `unnamed_file_${moment().unix()}`,

            ...(file.size && { size: file.size || 0 }),
          }
          if (file.width && file.height) {
            media.width = file.width
            media.height = file.height
          }
          return media
        }) ?? defaults.media,
    destination_url: destination_url?.filter(Boolean) ?? defaults.destination_url,
    call_to_action: call_to_action || defaults.call_to_action,
    description: description || defaults.description,
    audience_id: audience_id || defaults.audience_id,
    excluded_variations: excluded_variations || defaults.excluded_variations,
    language: language || defaults.language,
  }
}

export function validateCreative(creative, { setupOnly } = { setupOnly: true }) {
  if (typeof creative.tc_run_id === 'undefined' || creative.tc_run_id === null)
    throw new Error('Invalid creative; missing "tc" or "tc_run_id"')

  if (setupOnly) return { valid: true }

  const { headline, body, destination_url, call_to_action, media, description } = creative

  if (![headline, body, destination_url].every((x) => Array.isArray(x) && x.filter(Boolean).length >= 1)) {
    return { valid: false, error: 'common.errors.fillRequired' }
  }

  if (!Array.isArray(media) || !media.length) {
    return { valid: false, error: 'common.errors.fillRequired' }
  }

  if (!description || !call_to_action) return { valid: false, error: 'common.errors.fillRequired' }

  if (destination_url.filter(Boolean).some((x) => !constants.URL_VALIDATION_REGEX.test(x))) {
    return { valid: false, error: 'common.errors.wrongUrlFormat' }
  }

  return { valid: true }
}

export function validateIntegrations(integrations, accounts) {
  if (!Array.isArray(integrations)|| !Array.isArray(accounts)) return false

  if (integrations.every((x) => x.asset_class !== AssetClass.ad_account)) return false

  if (!integrations.some((x) => x.asset_class === AssetClass.profile && Platform.facebook === x.platform))
    return false
  
  // all integrations are valid in accounts
  return integrations.every(x => !!accounts.find(acc => acc.asset_id === x.asset_id)?.valid)
}

export function getCampaignRuntime(event) {
  const today = new moment()
  const eventDate = new Date(event.date)
  const fourMonthsBeforeEvent = moment(event.date).subtract(4, 'months')
  const startDate = fourMonthsBeforeEvent.isAfter(moment()) ? fourMonthsBeforeEvent : moment()

  const daysDiff = Math.round(new moment.duration(eventDate - today).asDays())
  const number_of_days_to_event = daysDiff > 0 ? daysDiff - 1 : daysDiff

  const endDate = today.clone().add(number_of_days_to_event, 'days')

  return [startDate, endDate]
}
