import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import rehypeRaw from 'rehype-raw'
import rehypeMinifyWhitespace from 'rehype-minify-whitespace'

import { getEnumMemberName } from 'utils/helpers'

import logo from 'images/logos/primary_white.png'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

const pdfStyles = StyleSheet.create({
  page: {
    padding: '0',
  },

  logo: {
    height: 35,
    width: 110,
  },

  section: {
    padding: '16px 32px',
  },

  sectionHeadline: {
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: 500,
  },

  addressLine: {
    marginTop: '6px',
    display: 'flex',
    flexDirection: 'row',
  },

  addressLineValues: {
    color: '#818181',
    fontSize: '10px',
    marginRight: '12px',
    display: 'flex',
    flexDirection: 'row',
  },

  notificationTopic: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
    marginTop: '6px',
    marginBottom: '16px',
  },

  notificationTopicText: {
    fontSize: '13px',
    fontWeight: 500,
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: '#7195ad',
    padding: '2px 7px 4px 7px',
    display: 'inline',
    borderRadius: '3px',
  },

  svg: {
    marginRight: '4px',
  },

  header: {
    backgroundColor: '#031C41',
    color: '#fff',
    padding: '16px 32px',
  },

  footer: {
    backgroundColor: '#031C41',
    color: '#fff',
    padding: '16px 32px',
    alignSelf: 'flex-end',
    marginTop: 'auto',
    width: '100%',
    fontSize: '8px',
  },

  footerText: {
    marginTop: '4px',
  },

  paragraph: {
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'light',
    color: '#616060',
  },
  bold: {
    fontWeight: 'bold',
    color: '#333',
  },
  italic: {
    fontStyle: 'italic',
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  list: {
    marginBottom: 10,
  },
  listItem: {
    marginLeft: 10,
    fontSize: 12,
    marginBottom: 5,
    color: '#616060',
  },
  lineBreak: {
    marginBottom: 5,
  },
  notificationBody: {
    marginTop: 8,
    padding: 16,
    borderRadius: 7,
    border: '1px solid #e8e9ee',
  }
})

const components = {
  // eslint-disable-next-line react/display-name, react/prop-types, react/prop-types
  p: ({ children }) => <Text style={pdfStyles.paragraph}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  strong: ({ children }) => <Text style={pdfStyles.bold}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  em: ({ children }) => <Text style={pdfStyles.italic}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  h1: ({ children }) => <Text style={{ ...pdfStyles.heading, fontSize: 18 }}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  h2: ({ children }) => <Text style={{ ...pdfStyles.heading, fontSize: 16 }}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  h3: ({ children }) => <Text style={{ ...pdfStyles.heading, fontSize: 15 }}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  h4: ({ children }) => <Text style={{ ...pdfStyles.heading, fontSize: 14 }}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  h5: ({ children }) => <Text style={{ ...pdfStyles.heading, fontSize: 12 }}>{children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  ul: ({ children }) => <View style={pdfStyles.list}>{children}</View>,
  // eslint-disable-next-line react/display-name, react/prop-types
  li: ({ children }) => <Text style={pdfStyles.listItem}>• {children}</Text>,
  // eslint-disable-next-line react/display-name, react/prop-types
  br: () => <Text style={pdfStyles.lineBreak}>{' '}</Text>,
}

export default function NotificationPDF({ notification }) {
  const { t } = useTranslation()

  return (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.header}>
          <Image src={logo} style={pdfStyles.logo} />
        </View>

        <View style={pdfStyles.section}>
          <Text style={pdfStyles.sectionHeadline}>{notification.title}</Text>
          <View style={pdfStyles.addressLine}>
            <View style={pdfStyles.addressLineValues}>
              <Text style={{ marginRight: 6 }}>{t('common.createdAt')}</Text>
              <Text>
                {moment
                  .utc(notification.date_created)
                  .format('DD.MM.YYYY HH:mm')}
              </Text>
            </View>
          </View>
          <View style={pdfStyles.notificationTopic}>
            <Text style={pdfStyles.notificationTopicText}>
              {getEnumMemberName(notification.notification_topic).toLowerCase()}
            </Text>
          </View>
          <View style={pdfStyles.notificationBody}>
            <ReactMarkdown components={components} rehypePlugins={[rehypeRaw, rehypeMinifyWhitespace]}>
              {notification.body}
            </ReactMarkdown>
          </View>
        </View>

        {/* FOOTER  */}
        <View style={pdfStyles.footer}>
          <Text style={pdfStyles.addressLine}></Text>
          <Text style={pdfStyles.footerText}>
            <p>
              Future Demand GmbH | Köpenicker Str. 145, 10997 Berlin | main +49 30 403 656 100 |
              support 0800 009496
            </p>
            <p>
              Company headquart: Berlin | Registry court: District court Charlottenburg, HRB 231043
              B | CEO: Hannes Tronsberg
            </p>
          </Text>
        </View>
      </Page>
    </Document>
  )
}

NotificationPDF.propTypes = {
  notification: PropTypes.object.isRequired,
}
