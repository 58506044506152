import React, {
  createContext,
  useMemo,
  useEffect,
  useCallback,
  useReducer,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { debounce, isEqual } from 'lodash'
import i18n from 'i18n'
import * as Sentry from '@sentry/react'

import setupProcessApi from 'api/setupProcesses'
import { useImmerReducerAsync } from 'hooks/useReducerAsync'
import {
  AssetClass,
  buildSetupPayload,
  Platform,
  prepareTargetingPayload,
  validateCreative,
  validateIntegrations,
} from 'api/models'
import { initStateObject, objectReducer, UPDATE } from 'reducers/default'
import { validateLocationTargeting } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import { userReportsApi } from 'api/userReports'
import { TargetingContextProvider } from '../Edit/CampaignForm/Targeting/TargetingContext'
import useHasPermission from 'hooks/useHasPermission'
import { offsetVariationsToHandleRemovedCreativeFields } from './disabledVariations'
import campaignSetupApi from 'api/campaigns'
import { codifyAPIError } from 'utils/helpers'
import metaAuthorizationApi from 'api/connect/meta'
import integrationsApi from 'api/integrations'
import { actions } from './actionTypes'
import { reducer } from './reducer'
import {
  _checkDisabledVariationsIntegrity,
  buildTargetingPayload,
  calculateSetupCreativesFromUpdatedTCCreatives,
  getDisabledVariationHashes,
  hasCustomAudience,
} from './util'

const CampaignSetupContext = createContext(null)
const CampaignSetupDispatchContext = createContext(null)
export const CurrentStepContext = createContext(null)
export const CurrentStepDispatchContext = createContext(null)
export const AvailableTasteClustersContext = createContext(null)

export function CampaignSetupStepProvider({ children }) {
  const [currentStepState, dispatchCurrentStep] = useReducer(objectReducer, {
    ...initStateObject,
    content: {
      currentStep: 1,
      currentCompletedStep: null,
    },
  })

  const setCurrentStep = useCallback(
    (step, completedStep = null) => {
      const payload = completedStep ? { currentCompletedStep: completedStep } : {}
      dispatchCurrentStep({ type: UPDATE, payload: { ...payload, currentStep: step } })
    },
    [dispatchCurrentStep],
  )

  const goToNextStep = useCallback(() => {
    if (currentStepState.content.currentStep === 5) return

    const { currentStep, currentCompletedStep } = currentStepState.content
    setCurrentStep(currentStep + 1, currentCompletedStep + 1)
  }, [currentStepState, setCurrentStep])

  const goToPrevStep = useCallback(() => {
    if (currentStepState.content.currentStep === 1) return

    const { currentStep, currentCompletedStep } = currentStepState.content
    setCurrentStep(currentStep - 1, currentCompletedStep - 1)
  }, [currentStepState, setCurrentStep])

  return (
    <CurrentStepContext.Provider value={currentStepState.content}>
      <CurrentStepDispatchContext.Provider value={{ setCurrentStep, goToNextStep, goToPrevStep }}>
        {children}
      </CurrentStepDispatchContext.Provider>
    </CurrentStepContext.Provider>
  )
}

CampaignSetupStepProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * Provides the context for available taste clusters.
 * For now, it's mainly used to keep track of the open taste clusters.
 *
 * @component
 */
function AvailableTasteClustersContextProvider({ children }) {
  const openTCs = useState([])

  return (
    <AvailableTasteClustersContext.Provider value={{ openTCs }}>
      {children}
    </AvailableTasteClustersContext.Provider>
  )
}

AvailableTasteClustersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function CampaignSetupProvider({ eventId, campaign, children }) {
  const hasWaveAccess = useHasPermission(Permissions.wave)
  const initialState = useMemo(() => {
    if (!eventId) return null

    if (!campaign || !campaign.latest_campaign_setup) {
      return {
        ..._initialState,
        eid: eventId,
      }
    }

    const creative = campaign.latest_campaign_setup.creative

    return {
      ..._initialState,
      eid: eventId,
      isPublished: true,
      setup: {
        ...campaign.latest_campaign_setup,
        creatives: [
          {
            ...creative,
            tc: campaign.tc,
            tc_run_id: campaign.tc_run_id,
            audience_id: creative.audience_id,
          },
        ],
      },
    }
  }, [eventId, campaign])

  const [state, dispatch] = useImmerReducerAsync(reducer, initialState, asyncActionHandlers)
  const { eid, setup, accounts, loading, hasPendingMedia, isPublished } = state
  const shouldAutosync = useMemo(() => !campaign?.latest_campaign_setup, [campaign])
  const [debouncedPending, setPendingDebounce] = useState(false)
  const disabledVariationsHashRef = useRef(isPublished ? getDisabledVariationHashes(setup) : {})

  useEffect(() => {
    if (!setup) {
      disabledVariationsHashRef.current = {}
      return
    }
  }, [setup])

  const fetchSetup = useCallback(() => {
    const action = { type: actions.fetchAsync, payload: { eid } }
    fetchSetupAsync({ dispatch })(action).then(({ success, setup }) => {
      if (success && setup) {
        disabledVariationsHashRef.current = getDisabledVariationHashes(setup)
      }
    })
  }, [dispatch, eid])

  const debouncedSync = useRef(
    debounce((setup, callback = () => {}) => {
      setPendingDebounce(false)
      if (!shouldAutosync) {
        return
      }

      const action = { type: actions.syncAsync, payload: { eid, setup } }
      syncSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    }, 1000),
  )

  const sync = useCallback(
    (setup, callback = () => {}) => {
      const action = { type: actions.syncAsync, payload: { setup } }
      syncSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const startSetup = useCallback(
    (config, callback = () => {}) => {
      const action = {
        type: actions.createAsync,
        payload: { eid: state.eid, config },
      }
      createSetupAsync({ dispatch })(action).then(callback)
    },
    [dispatch, state],
  )

  const fetchAccounts = useCallback(() => {
    const action = { type: actions.fetchAccountsAsync }
    fetchAccountsAsync({ dispatch })(action)
  }, [dispatch])

  const enrichAccounts = useCallback(
    (accounts, callback) => {
      const action = { type: actions.validateAccountsAsync, payload: { accounts } }
      enrichAccountsAsync({ dispatch })(action).then(callback)
    },
    [dispatch],
  )

  const enrichAsset = useCallback(
    (asset) => {
      const { asset_id, asset_class, platform } = asset
      const account = accounts.find(
        (x) => x.asset_id === asset_id && x.asset_class === asset_class && x.platform === platform,
      )

      return {
        ...asset,
        ...account,
      }
    },
    [accounts],
  )

  const getAdAccount = useCallback(() => {
    if (!setup) {
      return null
    }

    const selectedAdAccount = setup.integration_details?.find(
      (x) => x.asset_class === AssetClass.ad_account && x.platform === Platform.facebook,
    )
    if (!selectedAdAccount) {
      return null
    }

    return enrichAsset(selectedAdAccount)
  }, [setup, enrichAsset])

  const enrichAccountsAndReconfigure = useCallback(
    (config, callback = () => {}) => {
      const action = {
        type: actions.enrichAccountsAndReconfigureAsync,
        payload: { eid: state.eid, config },
      }
      enrichAccountsAndReconfigureAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const reconfigureSetup = useCallback(
    (config, callback = () => {}) => {
      const action = {
        type: actions.reconfigureAsync,
        payload: { eid: state.eid, config },
      }
      reconfigureSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const cancelSetup = useCallback(
    (callback = () => {}) => {
      const action = {
        type: actions.deleteAsync,
        payload: { eid: state.eid },
      }
      deleteSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state],
  )

  const setHasPendingMedia = useCallback(
    (hasPendingMedia) => {
      dispatch({ type: actions.setHasPendingMedia, payload: hasPendingMedia })
    },
    [dispatch],
  )

  const overwriteTCCreatives = useCallback(
    ({ tc, tc_run_id, creatives, creativeAction }) => {
      const { removedFields } = creativeAction
      if (removedFields) {
        creatives.excluded_variations = offsetVariationsToHandleRemovedCreativeFields(
          removedFields,
          setup.creatives,
          tc,
        )
      }

      dispatch({
        type: actions.setTCCreatives,
        payload: { tc, tc_run_id, ...creatives },
      })

      const newCreatives = calculateSetupCreativesFromUpdatedTCCreatives(
        setup.creatives,
        tc,
        tc_run_id,
        creatives,
      )

      if (shouldAutosync) {
        debouncedSync.current.cancel()
        setPendingDebounce(true)
        debouncedSync.current({ ...setup, creatives: newCreatives })
      }
    },
    [dispatch, setup, shouldAutosync],
  )

  const updateDisabledAdVariations = useCallback(
    (disabledVariations, callback = () => {}, autoSync = true) => {
      const creatives = setup.creatives.map((creative) => ({
        ...creative,
        excluded_variations: disabledVariations[creative.tc],
      }))

      dispatch({
        type: actions.setCreatives,
        payload: creatives,
      })

      if (autoSync) {
        sync({ ...setup, creatives }, ({ success, data }) => {
          if (success) {
            disabledVariationsHashRef.current = getDisabledVariationHashes({ ...setup, creatives })
          }
          callback({ success, data })
        })
      }
    },
    [dispatch, setup, sync],
  )

  const overwriteTCTargeting = useCallback(
    (tcTargeting, callback = () => {}) => {
      const action = {
        type: actions.updateTCTargetingAsync,
        payload: { ...tcTargeting, shouldSync: !isPublished },
      }

      updateTCTargetingAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state, isPublished],
  )

  const checkDisabledVariationsIntegrity = useCallback(
    (currentDisabledVariations = null) => {
      const creatives = setup.creatives
      const integrityHashes = disabledVariationsHashRef.current

      return _checkDisabledVariationsIntegrity(
        creatives,
        integrityHashes,
        currentDisabledVariations,
      )
    },
    [setup],
  )

  useEffect(() => {
    if (!setup) {
      return
    }

    const disabledVariations = setup.creatives.reduce((acc, c) => {
      acc[c.tc] = c.excluded_variations || []
      return acc
    }, {})

    if (!isEqual(disabledVariations, state.disabledVariations)) {
      dispatch({ type: actions.setSetupDisabledVariations, payload: disabledVariations })
    }
  }, [setup, dispatch, state.disabledVariations])

  const publishSetup = useCallback(
    (callback = () => {}) => {
      // force re-validation by fetching the setup
      let action = { type: actions.fetchAsync, payload: { eid } }
      fetchSetupAsync({ dispatch })(action).then(({ success, setup }) => {
        if (success) {
          enrichAccountsAsync({ dispatch })({
            type: actions.validateAccountsAsync,
            payload: { accounts: setup.integration_details },
          }).then(({ success, hasInvalidAccounts }) => {
            if (success && !hasInvalidAccounts) {
              const isIntegrationsValid = validateIntegrations(setup.integration_details, accounts)
              const isCreativesValid = setup.creatives.every(
                (x) => validateCreative(x, { setupOnly: false }).valid,
              )
              const isTargetingValid = setup.targeting.every(
                (x) =>
                  validateLocationTargeting(
                    setup.targeting,
                    x.tc,
                    x.tc_run_id,
                    hasCustomAudience(x.tc, setup.creatives),
                  ).valid,
              )

              if (isIntegrationsValid && isCreativesValid && isTargetingValid) {
                action = { type: actions.publicAsync }
                publishSetupAsync({ dispatch, getState: () => state })(action).then(callback)
              } else {
                Sentry.captureMessage('Invalid setup', {
                  extra: {
                    eid,
                    isIntegrationsValid,
                    isCreativesValid,
                    isTargetingValid,
                  },
                })

                callback({ success: false, reason: 'INVALID_SETUP' })
              }
            } else {
              callback({ success: false, reason: 'INVALID_ACCOUNTS' })
            }
          })
        }
      })
    },
    [dispatch, state, eid, accounts],
  )

  const editPublishedSetup = useCallback(
    (callback = () => {}, disabledVariations = null) => {
      const action = {
        type: actions.editAsync,
        payload: { tc: campaign.tc, tc_run_id: campaign.tc_run_id, disabledVariations },
      }
      editPublishedSetupAsync({ dispatch, getState: () => state })(action).then(callback)
    },
    [dispatch, state, campaign],
  )

  const isTargetingValid = useMemo(() => {
    if (!Array.isArray(setup?.targeting)) return false

    const targeting = setup.targeting
    return targeting.every(
      (x) =>
        validateLocationTargeting(
          targeting,
          x.tc,
          x.tc_run_id,
          hasCustomAudience(x.tc, setup.creatives),
        ).valid,
    )
  }, [setup])

  const isIntegrationsValid = useMemo(
    () => setup && validateIntegrations(setup.integration_details, accounts),
    [setup, accounts],
  )

  const isCreativesValid = useMemo(
    () => setup && setup.creatives.every((x) => validateCreative(x, { setupOnly: false }).valid),
    [setup],
  )

  const setError = useCallback(
    (error) => {
      dispatch({ type: actions.error, payload: error })
    },
    [dispatch],
  )

  const isStepValid = useCallback(
    (step) => {
      switch (step) {
        case 1:
          return true
        case 2:
          return isIntegrationsValid && !loading
        case 3:
          return (
            isIntegrationsValid &&
            isCreativesValid &&
            isTargetingValid &&
            !hasPendingMedia &&
            !debouncedPending &&
            !loading
          )
        case 4:
          return (
            isIntegrationsValid &&
            isCreativesValid &&
            isTargetingValid &&
            hasWaveAccess &&
            !loading &&
            !debouncedPending
          )
        default:
          return false
      }
    },
    [
      hasWaveAccess,
      isIntegrationsValid,
      isCreativesValid,
      isTargetingValid,
      hasPendingMedia,
      debouncedPending,
      loading,
    ],
  )

  useEffect(() => {
    if (eventId && !campaign) {
      fetchSetup()
    }
  }, [eventId, fetchSetup, campaign])

  useEffect(() => {
    dispatch({ type: actions.setCreativesValid, payload: isCreativesValid })
  }, [dispatch, isCreativesValid])

  useEffect(() => {
    dispatch({ type: actions.setTargetingValid, payload: isTargetingValid })
  }, [dispatch, isTargetingValid])

  useEffect(() => {
    dispatch({ type: actions.setIntegrationsValid, payload: isIntegrationsValid })
  }, [dispatch, isIntegrationsValid])

  return (
    <CampaignSetupStepProvider>
      <CampaignSetupContext.Provider value={state}>
        <AvailableTasteClustersContextProvider>
          <CampaignSetupDispatchContext.Provider
            value={{
              dispatch,
              fetchSetup,
              startSetup,
              cancelSetup,
              checkDisabledVariationsIntegrity,
              publishSetup,
              isStepValid,
              reconfigureSetup,
              overwriteTCCreatives,
              overwriteTCTargeting,
              updateDisabledAdVariations,
              editPublishedSetup,
              setHasPendingMedia,
              fetchAccounts,
              enrichAccountsAndReconfigure,
              enrichAccounts,
              enrichAsset,
              getAdAccount,
              setError,
            }}
          >
            <TargetingContextProvider>{children}</TargetingContextProvider>
          </CampaignSetupDispatchContext.Provider>
        </AvailableTasteClustersContextProvider>
      </CampaignSetupContext.Provider>
    </CampaignSetupStepProvider>
  )
}

CampaignSetupProvider.propTypes = {
  eventId: PropTypes.string.isRequired,
  campaign: PropTypes.shape({
    tc: PropTypes.string.isRequired,
    tc_run_id: PropTypes.number.isRequired,
    latest_campaign_setup: PropTypes.object,
  }),
  children: PropTypes.node.isRequired,
}

export const useCampaignSetup = () => {
  const context = React.useContext(CampaignSetupContext)

  if (context === undefined) {
    throw new Error('useCampaignSetup must be used within a CampaignSetupProvider')
  }

  return context
}

export const useCampaignSetupDispatch = () => {
  const context = React.useContext(CampaignSetupDispatchContext)

  if (context === undefined) {
    throw new Error('useCampaignSetupDispatch must be used within a CampaignSetupProvider')
  }

  return context
}

export const useCurrentStep = () => {
  const context = React.useContext(CurrentStepContext)

  if (context === undefined) {
    throw new Error('useCurrentStep must be used within a CampaignSetupProvider')
  }

  return context
}

export const useCurrentStepDispatch = () => {
  const context = React.useContext(CurrentStepDispatchContext)

  if (context === undefined) {
    throw new Error('useCurrentStepDispatch must be used within a CampaignSetupProvider')
  }

  return context
}

const _initialState = {
  loading: false,
  hasPendingMedia: false,
  error: null,
  lastSyncedAt: null,
  eid: null,
  setup: null,
  isCreativesValid: false,
  isIntegrationsValid: false,
  isTargetingValid: false,
  disabledVariations: {},
  accounts: [],
}

export const asyncActionHandlers = {
  [actions.syncAsync]: syncSetupAsync,
  [actions.fetchAsync]: fetchSetupAsync,
  [actions.createAsync]: createSetupAsync,
  [actions.reconfigureAsync]: reconfigureSetupAsync,
  [actions.deleteAsync]: deleteSetupAsync,
  [actions.updateTCTargetingAsync]: updateTCTargetingAsync,
  [actions.publicAsync]: publishSetupAsync,
  [actions.editAsync]: editPublishedSetupAsync,
  [actions.fetchAccountsAsync]: fetchAccountsAsync,
  [actions.validateAccountsAsync]: enrichAccountsAsync,
  [actions.enrichAccountsAndReconfigureAsync]: enrichAccountsAndReconfigureAsync,
}

export function fetchAccountsAsync({ dispatch }) {
  return async () => {
    dispatch({ type: actions.loading })
    const response = await integrationsApi.fetchAccountsAsync()
    if (!response.error) {
      dispatch({
        type: actions.setAccounts,
        payload: response.data.map((x) => ({ ...x, valid: !!x.is_active })),
      })
      return { success: true, data: response.data }
    }

    handleError(dispatch, response)

    return { success: false }
  }
}

function enrichAccountsAsync({ dispatch }) {
  return async (action) => {
    const { accounts } = action.payload
    dispatch({ type: actions.loading })

    const response = await metaAuthorizationApi.enrichAssets(accounts)

    if (response.error) {
      handleError(dispatch, response)
      return { success: false }
    }

    const newAccounts = accounts.map((account) => {
      const enrichedAccount = response.data.find(
        (x) =>
          x.asset_id === account.asset_id &&
          x.asset_class === account.asset_class &&
          x.platform === account.platform,
      )
      if (!enrichedAccount) {
        return { ...account, valid: false }
      }

      return { ...account, ...enrichedAccount, valid: enrichedAccount.valid }
    })

    const hasInvalidAccounts = newAccounts.some((x) => !x.valid)

    dispatch({ type: actions.patchAccounts, payload: newAccounts })
    return { success: true, data: response.data, hasInvalidAccounts }
  }
}

function enrichAccountsAndReconfigureAsync({ dispatch, getState }) {
  return async (action) => {
    // validate accounts, upon success, and if all accounts are valid, reconfigure the setup
    const { eid, config } = action.payload
    let { integration_details: accounts } = config
    accounts = accounts || getState().setup.integration_details

    if (!accounts) {
      const reconfigureAction = { type: actions.reconfigureAsync, payload: { eid, config } }
      return reconfigureSetupAsync({ dispatch, getState })(reconfigureAction)
    }

    const validateAction = { type: actions.validateAccountsAsync, payload: { accounts } }
    const {
      success,
      data: enrichedPartnerAccounts,
      hasInvalidAccounts,
    } = await enrichAccountsAsync({ dispatch })(validateAction)

    if (!success || hasInvalidAccounts) {
      dispatch({ type: actions.error, payload: 'common.genericError' })

      return { success: false, hasInvalidAccounts }
    }

    // enrich selected accounts
    const newIntegrations = accounts.map((x) => {
      const enrichedAccount = enrichedPartnerAccounts.find(
        (y) =>
          y.asset_id === x.asset_id && y.asset_class === x.asset_class && y.platform === x.platform,
      )
      if (!enrichedAccount) {
        return x
      }

      return { ...x, ...enrichedAccount }
    })

    const reconfigureAction = {
      type: actions.reconfigureAsync,
      payload: { eid, config: { ...config, integration_details: newIntegrations } },
    }
    return reconfigureSetupAsync({ dispatch, getState })(reconfigureAction)
  }
}

function deleteSetupAsync({ dispatch, getState }) {
  return async (action) => {
    const { eid, cancel } = action.payload
    const {
      setup: { id },
    } = getState()
    dispatch({ type: actions.loading })

    const response = await setupProcessApi.deleteAsync(eid, cancel)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: null })
      await userReportsApi.deleteUserReports({ setup_process_ids: [id] })
      dispatch({ type: actions.fetchAsync, payload: { eid } })

      return { success: true }
    } else {
      handleError(dispatch, response)

      return { success: false }
    }
  }
}

function createSetupAsync({ dispatch }) {
  return async (action) => {
    const { eid, config } = action.payload
    const defaultLanguage = i18n.language

    const payload = buildSetupPayload(
      {
        ...config,
        creatives: config.creatives.map((creative) => ({
          ...creative,
          language: creative.language || defaultLanguage,
        })),
      },
      { setupOnly: true, setDefaultTargeting: true },
    )

    dispatch({ type: actions.loading })

    const response = await setupProcessApi.createAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        data: response.data,
      }
    } else {
      handleError(dispatch, response)

      return {
        success: false,
        data: response.data,
      }
    }
  }
}

function fetchSetupAsync({ dispatch }) {
  return async (action) => {
    const { eid } = action.payload
    dispatch({ type: actions.loading })

    const response = await setupProcessApi.fetchAsync(eid)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        setup: response.data,
      }
    } else if (response.status === 404) {
      dispatch({ type: actions.setupLoaded, payload: null })

      return {
        success: true,
        setup: null,
      }
    } else {
      handleError(dispatch, response)

      return { success: false }
    }
  }
}

function syncSetupAsync({ dispatch, getState }) {
  return async (action) => {
    dispatch({ type: actions.loading })
    let { eid, setup } = getState()
    if (action?.payload) {
      setup = action.payload.setup
    }

    const payload = buildSetupPayload(setup)

    const response = await setupProcessApi.updateAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupSynced, payload: Date.now() })

      return {
        success: true,
        data: response.data,
      }
    }

    handleError(dispatch, response)

    return { success: false }
  }
}

function reconfigureSetupAsync({ dispatch, getState }) {
  return async (action) => {
    const { eid, config } = action.payload
    const { setup } = getState()
    const { creatives: currentCreatives } = setup
    const language = currentCreatives.length > 0 ? currentCreatives[0].language : i18n.language

    const newCreatives = config.creatives.map((c) => {
      const currentCreative = currentCreatives.find(
        (cc) => cc.tc === c.tc && cc.tc_run_id === c.tc_run_id,
      )
      if (currentCreative) {
        return { ...currentCreative, ...c }
      }

      // a new creative
      return { ...c, language }
    })

    const targeting = setup.targeting.filter((x) => config.creatives.some((y) => y.tc === x.tc))

    dispatch({ type: actions.loading })

    const payload = buildSetupPayload(
      { ...setup, ...config, creatives: newCreatives, targeting },
      { setupOnly: true, setDefaultTargeting: true },
    )
    const response = await setupProcessApi.updateAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        data: response.data,
      }
    } else {
      handleError(dispatch, response)

      return {
        success: false,
        data: response.data,
      }
    }
  }
}

function updateTCTargetingAsync({ dispatch, getState }) {
  return async (action) => {
    const { eid, setup } = getState()
    const { shouldSync, ...targetingPayload } = action.payload
    const newTargeting = buildTargetingPayload(setup, targetingPayload)
    dispatch({ type: actions.setTargeting, payload: newTargeting })

    if (!shouldSync) {
      return { success: true }
    }

    const payload = buildSetupPayload(
      { ...setup, targeting: newTargeting },
      { setupOnly: true, setDefaultTargeting: true },
    )

    const response = await setupProcessApi.updateAsync(eid, payload)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return {
        success: true,
        data: response.data,
      }
    }

    handleError(dispatch, response)

    return {
      success: false,
      data: response.data,
    }
  }
}

function publishSetupAsync({ dispatch, getState }) {
  return async () => {
    const { eid } = getState()
    dispatch({ type: actions.loading })

    const response = await setupProcessApi.publishAsync(eid)
    if (response.status === 200) {
      dispatch({ type: actions.setupLoaded, payload: response.data })

      return { success: true }
    }

    handleError(dispatch, response)

    return { success: false }
  }
}

function editPublishedSetupAsync({ dispatch, getState }) {
  return async ({ payload: { tc, tc_run_id, disabledVariations } }) => {
    const { eid, setup } = getState()
    dispatch({ type: actions.loading })

    const creative = setup.creatives.find((c) => c.tc === tc && c.tc_run_id === tc_run_id)
    const creatives = {
      ...creative,
      body: creative.body.filter(Boolean),
      headline: creative.headline.filter(Boolean),
      destination_url: creative.destination_url.filter(Boolean),
    }

    if (disabledVariations) {
      creatives.excluded_variations = disabledVariations[tc]
    }

    if (!creatives.language) {
      creatives.language = i18n.language
    }

    const targeting = prepareTargetingPayload(setup.targeting, [creatives])[0]
    const { error, status } = await campaignSetupApi.updateAsync(eid, {
      tc: tc,
      tc_run_id: tc_run_id,
      creatives,
      targeting,
      audience_id: creatives.audience_id,
    })

    if (!error) {
      dispatch({ type: actions.setupLoaded, payload: null })

      return { success: true }
    }

    handleError(dispatch, { error, status })

    return { success: false }
  }
}

function handleError(dispatch, { error, status, message, data }) {
  Sentry.captureException(error)
  console.error('Failed to save setup', error, status, message, data)

  const errorCode = codifyAPIError({ error, status, message, data })
  dispatch({ type: actions.error, errorCode })
}
