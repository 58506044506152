import React from 'react'
import SVG from 'react-inlinesvg'

//icons
import venueUrl from './icons/venue.svg'
import clockUrl from './icons/clock.svg'
import pinUrl from './icons/pin.svg'
import checkUrl from './icons/check.svg'
import cloudUploadUrl from './icons/cloud_upload.svg'
import removeIconUrl from './icons/remove-icon.svg'
import greenTickUrl from './icons/green-check.svg'
import circleTickUrl from './icons/navy_tick.svg'
import errorUrl from './icons/error.svg'
import warningUrl from './icons/warning.svg'
import warningCircleUrl from './icons/warning-circle.svg'
import warningTriangle from './icons/warning_triangle.svg'
import publicUrl from './icons/public.svg'
import moreHorizUrl from './icons/more_horiz.svg'
import thumbUpUrl from './icons/thumb_up.svg'
import chatBubbleOutlineUrl from './icons/chat_bubble_outline.svg'
import chatBubbleInstaUrl from './icons/chat_bubble_insta.svg'
import facebookShareUrl from './icons/facebook_share.svg'
import cancelledUrl from './icons/cancelled.svg'
import heartUrl from './icons/heart.svg'
import sendUrl from './icons/send.svg'
import arrowForwardUrl from './icons/arrow_forward_ios.svg'
import bookmarkOutlineUrl from './icons/bookmark_outline.svg'
import cameraUrl from './icons/camera.svg'
import cancelUrl from './icons/cancelled.svg'
import searchUrl from './icons/search.svg'
import searchSyncNavyUrl from './icons/search-sync-navy.svg'
import syncUrl from './icons/sync.svg'
import calenderUrl from './icons/calender.svg'
import arrowDownUrl from './icons/arrow-down.svg'
import arrowDownThreeUrl from './icons/arrow-down-three.svg'
import lightBulbOutline from './icons/lightbulb_outline.svg'
import impressionsUrl from './icons/visibility.png'
import touchUrl from './icons/touch.png'
import walletUrl from './icons/wallet.svg'
import connectedUrl from './icons/connected.svg'
import alarmUrl from './icons/alarm.svg'
import mergRedUrl from './icons/merge-red.svg'
import editUrl from './icons/edit.svg'
import eventGreyUrl from './icons/event_grey.svg'
import eventUrl from './icons/event.svg'
import envelopeUrl from './icons/envelop.svg'
import userNavyUrl from './icons/user-navy.svg'
import worldUrl from './icons/world.svg'
import leftArrowUrl from './icons/leftArrow.svg'
import rightArrowUrl from './icons/rightArrow.svg'
import leftArrowCircleUrl from './icons/arrow-left-circle.svg'
import rightArrowCircleUrl from './icons/arrow-right-circle.svg'
import tickWrappedUrl from './icons/green_tick_wrapped.svg'
import descriptionUrl from './icons/description.svg'
import playlistUrl from './icons/playlist.svg'
import campaignUrl from './icons/campaign.svg'
import exportUrl from './icons/export.svg'
import importUrl from './icons/import.svg'
import donutUrl from './icons/donut.svg'
import binUrl from './icons/bin.svg'
import minusCircleUrl from './icons/minus-circle.svg'
import categoryUrl from './icons/category.svg'
import dotsMenuUrl from './icons/dots-menu.svg'
import chevronUrl from './icons/chevron.svg'
import starUrl from './icons/star.svg'
import startFilledUrl from './icons/star-filled.svg'
import drawerUrl from './icons/drawer-red.svg'
import cashUrl from './icons/cash.svg'
import copyUrl from './icons/copy_icon.svg'
import phiUrl from './icons/phi.svg'
import sigmaUrl from './icons/sigma.svg'
//navbar
import demandPredictionUrl from './navbar/app_demandprediction.svg'
import contentExpertUrl from './navbar/app_contentexpert.svg'
import sponsorshipActivationUrl from './navbar/app_sponsorshipactivation.svg'
import homeUrl from './navbar/app_home_navy.svg'
import salesBoostUrl from './navbar/app_salesboost.svg'
import backhaulUrl from './navbar/app_backhaul.png'
import backhaulSvgUrl from './navbar/app_backhaul.svg'
import lookoutUrl from './navbar/app_lookout.png'
import lookoutSvgUrl from './navbar/app_lookout.svg'
import sentinelUrl from './navbar/app_sentinel.png'
import waveUrl from './navbar/app_wave.png'
import waveSvgUrl from './navbar/app_wave.svg'
import uploadFilesUrl from './navbar/app_uploadfiles.svg'
import notificationsUrl from './navbar/app_notifications.svg'
import settingsUrl from './navbar/app_settings.svg'
import logoutUrl from './navbar/app_logout.svg'
//logos
import expandedLogoUrl from './logos/primary_white.svg'
import logoWhiteUrl from './logos/app-icon-white.svg'

function withSvg(src) {
  return function WithSvg(props) {
    return <SVG {...props} src={src} />
  }
}

//icons
export const VenueIcon = withSvg(venueUrl)
export const ClockIcon = withSvg(clockUrl)
export const PinIcon = withSvg(pinUrl)
export const CheckIcon = withSvg(checkUrl)
export const CloudUpload = withSvg(cloudUploadUrl)
export const RemoveIcon = withSvg(removeIconUrl)
export const ErrorIcon = withSvg(errorUrl)
export const CircleTickIcon = withSvg(circleTickUrl)
export const GreenTickIcon = withSvg(greenTickUrl)
export const WarningIcon = withSvg(warningUrl)
export const WarningCircleIcon = withSvg(warningCircleUrl)
export const WarningTriangleIcon = withSvg(warningTriangle)
export const PublicIcon = withSvg(publicUrl)
export const MoreHorizIcon = withSvg(moreHorizUrl)
export const ThumbUpIcon = withSvg(thumbUpUrl)
export const ChatBubbleOutlineIcon = withSvg(chatBubbleOutlineUrl)
export const ChatBubbleInstaIcon = withSvg(chatBubbleInstaUrl)
export const FacebookShareIcon = withSvg(facebookShareUrl)
export const CancelledIcon = withSvg(cancelledUrl)
export const HeartIcon = withSvg(heartUrl)
export const SendIcon = withSvg(sendUrl)
export const ArrowForwardIcon = withSvg(arrowForwardUrl)
export const BookmarkOutlineIcon = withSvg(bookmarkOutlineUrl)
export const CameraIcon = withSvg(cameraUrl)
export const CancelIcon = withSvg(cancelUrl)
export const SearchSyncNavyIcon = withSvg(searchSyncNavyUrl)
export const SyncIcon = withSvg(syncUrl)
export const SearchIcon = withSvg(searchUrl)
export const CalenderIcon = withSvg(calenderUrl)
export const ArrowDownIcon = withSvg(arrowDownUrl)
export const ArrowDownThreeIcon = withSvg(arrowDownThreeUrl)
export const LightBulbOutlineIcon = withSvg(lightBulbOutline)
export const WalletIcon = withSvg(walletUrl)
export const ConnectedIcon = withSvg(connectedUrl)
export const EditIcon = withSvg(editUrl)
export const EventGreyIcon = withSvg(eventGreyUrl)
export const EventIcon = withSvg(eventUrl)
export const AlarmIcon = withSvg(alarmUrl)
export const MergeRedIcon = withSvg(mergRedUrl)
export const EnvelopeIcon = withSvg(envelopeUrl)
export const UserNavyIcon = withSvg(userNavyUrl)
export const WorldIcon = withSvg(worldUrl)
export const LeftArrowIcon = withSvg(leftArrowUrl)
export const RightArrowIcon = withSvg(rightArrowUrl)
export const LeftArrowCircleIcon = withSvg(leftArrowCircleUrl)
export const RightArrowCircleIcon = withSvg(rightArrowCircleUrl)
export const TickWrappedIcon = withSvg(tickWrappedUrl)
export const DescriptionIcon = withSvg(descriptionUrl)
export const PlaylistIcon = withSvg(playlistUrl)
export const CampaignIcon = withSvg(campaignUrl)
export const ExportIcon = withSvg(exportUrl)
export const ImportIcon = withSvg(importUrl)
export const DonutIcon = withSvg(donutUrl)
export const MinusCircleIcon = withSvg(minusCircleUrl)
export const ImpressionsImg = impressionsUrl
export const TouchImg = touchUrl
export const BinIcon = withSvg(binUrl)
export const CategoryIcon = withSvg(categoryUrl)
export const DotsMenuIcon = withSvg(dotsMenuUrl)
export const ChevronIcon = withSvg(chevronUrl)
export const DrawerIcon = withSvg(drawerUrl)
export const CashIcon = withSvg(cashUrl)
export const CopyIcon = withSvg(copyUrl)
export const PhiIcon = withSvg(phiUrl)
export const SigmaIcon = withSvg(sigmaUrl)
//navbar
export const DemandPredictionIcon = withSvg(demandPredictionUrl)
export const ContentExpertIcon = withSvg(contentExpertUrl)
export const sponsorshipActivationIcon = withSvg(sponsorshipActivationUrl)
export const HomeIcon = withSvg(homeUrl)
export const SalesBoostIcon = withSvg(salesBoostUrl)
export const BackhaulIcon = withSvg(backhaulUrl)
export const LookoutIcon = withSvg(lookoutUrl)
export const WaveIcon = withSvg(waveUrl)
export const BackhaulSVGIcon = withSvg(backhaulSvgUrl)
export const LookoutSVGIcon = withSvg(lookoutSvgUrl)
export const WaveSVGIcon = withSvg(waveSvgUrl)
export const UploadFilesIcon = withSvg(uploadFilesUrl)
export const NotificationsIcon = withSvg(notificationsUrl)
export const SettingsIcon = withSvg(settingsUrl)
export const LogoutIcon = withSvg(logoutUrl)
export const SentinelIcon = sentinelUrl
export const StarIcon = withSvg(starUrl)
export const StarFilledIcon = withSvg(startFilledUrl)
//logos
export const ExpandedLogoWhiteIcon = withSvg(expandedLogoUrl)
export const LogoWhiteIcon = withSvg(logoWhiteUrl)
export { sentinelUrl, waveUrl, lookoutUrl, backhaulUrl }
