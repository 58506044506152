import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

import SelectAdPlatformForm from 'components/Recommendations/Campaign/SelectAdPlatformForm'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import { useCampaignSetup, useCampaignSetupDispatch } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

const Accounts = ({ event, setShowCancelSetupModal, onBack, onNext }) => {
  const { t } = useTranslation()
  const { isStepValid, enrichAccountsAndReconfigure } = useCampaignSetupDispatch()
  const { setup } = useCampaignSetup()

  const isNextEnabled = isStepValid(2)

  //hides the mobile-nav bar
  useEffect(() => {
    const mobileNav = document.getElementById('mobile-navbar')
    if (mobileNav?.style) {
      mobileNav.style.display = 'none'
    }

    return () => {
      const mobileNav = document.getElementById('mobile-navbar')
      if (mobileNav?.style) {
        mobileNav.style.display = 'block'
      }
    }
  }, [])

  const onNextClick = useCallback(() => {
    enrichAccountsAndReconfigure(setup, ({ success, hasInvalidAccounts }) => {
      if (success && !hasInvalidAccounts) {
        onNext()
      }
    })
  }, [onNext, enrichAccountsAndReconfigure, setup])

  return (
    <div id="accounts">
      <SelectAdPlatformForm isDisabled={event.has_active_campaigns} />
      <div
        id="setup-btn-container"
        className="recommendation-setup-action-buttons d-flex justify-content-between align-items-center"
      >
        <div className="d-flex justify-content-between w-100">
          <TransparentBgButton
            className="bt"
            onClick={() => onBack()}
            text={t('Recommendations.btnBack')}
            fullWidth={false}
            color="navy"
          />
          <div className="d-flex gap-3">
            <TransparentBgButton
              text={t('Recommendations.btnCancelSetup')}
              onClick={() => setShowCancelSetupModal(true)}
              color="navy"
            />
            <SecondaryButton
              disabled={!isNextEnabled}
              className="bt"
              onClick={onNextClick}
              text={t('Recommendations.btnNext')}
              fullWidth={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Accounts.propTypes = {
  event: PropTypes.object.isRequired,
  setShowCancelSetupModal: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default Accounts
