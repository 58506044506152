import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { SecondaryButton } from 'components/Shared/Button'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import ErrorMessage from 'components/Shared/ErrorMessage'

import NotificationPDF from './NotificationPDF'
import { useNotification } from './notifications'

import { getEnumMemberName } from 'utils/helpers'
import useRedirectOnResize from 'hooks/useRedirectOnResize'

import { ClockIcon } from 'images'

function ActionUrl({ notification }) {
  const { t } = useTranslation()
  const { action_url: actionUrl } = notification
  const isAbsolute = actionUrl.startsWith('http') || actionUrl.startsWith('https') || actionUrl.startsWith('//')
  const isPDFDownload = actionUrl.startsWith('/download-pdf')

  if (isPDFDownload) {
    return (
      <PDFDownloadLink
        document={
          <NotificationPDF notification={notification} />
        }
        fileName={`${notification.title}-${moment().format('DD-MM-yyyy')}.pdf`}
      >
        {({ loading }) =>
          loading ? (
            t('common.generatingDocument')
          ) : (
            <button className="bt s-38 bt-orange">
              {t('common.more')}
            </button>
          )
        }
      </PDFDownloadLink>
    )
  }

  if (!isAbsolute) {
    return (
      <SecondaryButton classNames="notification-details_action-button" text={t('common.more')} linkTo={actionUrl} />
    )
  }

  return (
    <a
      className="bt bt-orange normal notification-details_action-button w-100 s-38"
      href={actionUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('common.more')}
    </a>
  )
}

ActionUrl.propTypes = {
  notification: PropTypes.object.isRequired,
}

function Details({ notification }) {
  return (
    <>
      <h1 className="notification-details_title" title={notification.title}>
        <Markdown rehypePlugins={[rehypeRaw]}>{notification.title}</Markdown>
      </h1>
      <span className="notification-details_time">
        <ClockIcon className="mr-1" height={12} />
        <time dateTime={moment(notification.date_created).format('DD.MM.YYYY, HH:mm')}>
          {moment(notification.date_created).format('DD.MM.YYYY, HH:mm')}
        </time>
      </span>

      <div className="notification-details_topic">
        {getEnumMemberName(notification.notification_topic).toLowerCase()}
      </div>

      <div className="notification-details_body">
        <Markdown rehypePlugins={[rehypeRaw]}>{notification.body}</Markdown>
      </div>

      {notification.action_url && <ActionUrl notification={notification} />}
    </>
  )
}

Details.propTypes = {
  notification: PropTypes.object.isRequired,
}

export default function NotificationDetails({ notificationId }) {
  const { t } = useTranslation()

  const shouldRedirect = useRedirectOnResize(991)

  try {
    notificationId = Number(notificationId)
  } catch (e) {
    notificationId = null
  }

  const {
    state: { content: notification, loading, error, message },
  } = useNotification(notificationId)

  if (shouldRedirect) {
    return <Redirect to={notificationId ? `/notifications/${notificationId}` : '/notifications'} />
  }

  return (
    <article className="notification-details">
      {loading && (
        <div className="card-body">
          <LoadingSpinner />
        </div>
      )}
      {error && (
        <div className="card-body">
          <ErrorMessage danger>{t([message, 'common.errors.unknownError'])}</ErrorMessage>
        </div>
      )}
      {notification?.id && <Details notification={notification} />}
    </article>
  )
}

NotificationDetails.propTypes = {
  notificationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
