import React, { createContext, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'

import { useImmerReducerAsync } from 'hooks/useReducerAsync'
import nlqApi from 'api/nlq'
import { codifyAPIError } from 'utils/helpers'

const SentinelContext = createContext(null)
const SentinelDispatchContext = createContext(null)

export const initialState = {
  localQuestionId: null,
  question: null,
  answer: null,
  results: { data: [], localQuestionId: null, outcome: null },
  selectedIds: new Set(),
  savedQuestions: {
    data: [],
    loading: false,
    error: null,
    activeQuestionId: null,
  },
  outcome: null,
  aggregationType: 'sum',
  loading: false,
  error: null,
  abortTip: null,
  questionChanged: false,
}

export const actions = Object.freeze({
  setQuestion: 'SET_QUESTION',
  setOutcome: 'SET_OUTCOME',
  setLoading: 'SET_LOADING',
  setError: 'SET_ERROR',
  setAbortRequestTip: 'SET_ABORT_REQUEST_TIP',
  setAnswer: 'SET_ANSWER',
  setResults: 'SET_RESULTS',
  setSavedQuestions: 'SET_SAVED_QUESTIONS',
  setSavedQuestionsLoading: 'SET_SAVED_QUESTIONS_LOADING',
  setSavedQuestionsError: 'SET_SAVED_QUESTIONS_ERROR',
  askAgain: 'ASK_AGAIN',
  setSelectedIds: 'SET_SELECTED_IDS',
  setLocalQuestionId: 'SET_LOCAL_QUESTION_ID',
  setActiveSavedQuestion: 'SET_ACTIVE_SAVED_QUESTION',
  setAggregationType: 'SET_AGGREGATION_TYPE',

  saveQuestionAsync: 'SAVE_QUESTION_ASYNC',
  fetchQuestionAsync: 'FETCH_QUESTION_ASYNC',
  textToNLQAsync: 'TEXT_TO_NLQ_ASYNC',
  askAgainAsync: 'ASK_AGAIN_ASYNC',
  exportToCSVAsync: 'EXPORT_TO_CSV_ASYNC',
  exportToExcelAsync: 'EXPORT_TO_EXCEL_ASYNC',
  dataFromNLQAsync: 'DATA_FROM_NLQ_ASYNC',
})

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setQuestion: {
      const question = action.payload
      state.questionChanged = true
      state.question = question
      state.localQuestionId = null
      state.savedQuestions.activeQuestionId = null
      if (!question) {
        // reset state when input is cleared
        state.answer = null
        state.results = { data: [], localQuestionId: null, outcome: null }
      }

      return state
    }
    case actions.setOutcome: {
      state.outcome = action.payload
      state.questionChanged = true
      state.localQuestionId = null
      state.savedQuestions.activeQuestionId = null
      return state
    }
    case actions.setLocalQuestionId: {
      state.localQuestionId = action.payload
      return state
    }
    case actions.setLoading: {
      state.loading = action.payload
      return state
    }
    case actions.setError: {
      state.error = action.payload
      state.loading = false
      return state
    }
    case actions.setAnswer: {
      state.answer = action.payload
      return state
    }
    case actions.setResults: {
      const { page, data, aggregations } = action.payload
      if (page === 1) {
        state.localQuestionId = v4()
      }
      state.results = {
        data: data,
        aggregations: aggregations,
        outcome: state.outcome,
        localQuestionId: state.localQuestionId,
      }
      state.loading = false
      state.error = false
      return state
    }
    case actions.setSavedQuestions: {
      state.savedQuestions.data = action.payload
      state.savedQuestions.loading = false
      state.savedQuestions.error = false
      return state
    }
    case actions.setSavedQuestionsLoading: {
      state.savedQuestions.loading = action.payload
      return state
    }
    case actions.setSavedQuestionsError: {
      state.savedQuestions.error = action.payload
      state.savedQuestions.loading = false
      return state
    }
    case actions.setSelectedIds: {
      state.selectedIds = action.payload
      return state
    }
    case actions.askAgain: {
      const question = state.savedQuestions.data.find((q) => q.id === action.payload)
      if (!question) {
        // eslint-disable-next-line quotes
        throw new Error("Couldn't find a saved question with the specified id", action.payload)
      }

      state.outcome = question.result_type
      state.question = question.original_description
      state.savedQuestions.activeQuestionId = action.payload
      state.localQuestionId = null

      return state
    }
    case actions.setActiveSavedQuestion: {
      state.savedQuestions.activeQuestionId = action.payload
      return state
    }
    case actions.setAbortRequestTip: {
      state.abortTip = action.payload
      return state
    }
    case actions.setQuestionChanged: {
      state.questionChanged = action.payload
      if (state.questionChanged) {
        state.answer = null
        state.results = { data: [], localQuestionId: null, outcome: null }
      }
      return state
    }
    case actions.setAggregationType: {
      state.aggregationType = action.payload
      return state
    }
    default:
      console.error(`Unknown action type provided to sentinel reducer ${action.type}`)
      return state
  }
}

export const asyncActionHandlers = {
  [actions.saveQuestionAsync]: saveQuestionAsync,
  [actions.fetchQuestionAsync]: fetchSavedQuestionsAsync,
  [actions.textToNLQAsync]: textToNLQAsync,
  [actions.askAgainAsync]: askAgainAsync,
  [actions.exportToCSVAsync]: exportToCSVAsync,
  [actions.exportToExcelAsync]: exportToExcelAsync,
  [actions.dataFromNLQAsync]: dataFromNLQAsync,
}

function saveQuestionAsync({ dispatch }) {
  return async (action) => {
    dispatch({ type: actions.setSavedQuestionsLoading, payload: true })
    const { queryId, name, save = true } = action.payload
    const response = await nlqApi.saveQuestion(queryId, name, save)

    if (response.error) {
      dispatch({ type: actions.setSavedQuestionsError, payload: response })
      return {
        success: false,
      }
    }

    dispatch({ type: actions.setActiveSavedQuestion, payload: queryId })
    dispatch({ type: actions.fetchQuestionAsync })
    return { success: true }
  }
}

function fetchSavedQuestionsAsync({ dispatch }) {
  return async () => {
    dispatch({ type: actions.setSavedQuestionsLoading, payload: true })
    const response = await nlqApi.fetchSavedQuestions()
    if (response.error) {
      dispatch({ type: actions.setSavedQuestionsError, payload: response })
      return
    }

    dispatch({ type: actions.setSavedQuestions, payload: response.data })
  }
}

function exportToCSVAsync({ dispatch }) {
  return async (action) => {
    let { queryId, ids } = action.payload
    const fileType = 'csv'
    dispatch({ type: actions.setLoading, payload: true })
    try {
      const response = await nlqApi.exportToCsvOrExcel(queryId, ids, fileType)
      if (response.error) {
        handleError(dispatch, response)
        return
      }

      const blob = new Blob([response.data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'Sentinel.csv'
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: actions.setLoading, payload: false })
    }
  }
}

function exportToExcelAsync({ dispatch }) {
  return async (action) => {
    let { queryId, ids } = action.payload
    const fileType = 'xlsx'
    dispatch({ type: actions.setLoading, payload: true })
    try {
      const response = await nlqApi.exportToCsvOrExcel(queryId, ids, fileType)
      if (response.error) {
        handleError(dispatch, response)
        return
      }

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'Sentinel.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      handleError(dispatch, error)
    } finally {
      dispatch({ type: actions.setLoading, payload: false })
    }
  }
}

function textToNLQAsync({ dispatch, getState }) {
  return async (action) => {
    const cancelToken = action.cancelToken
    dispatch({ type: actions.setQuestionChanged, payload: true })
    dispatch({ type: actions.setLoading, payload: true })
    dispatch({ type: actions.setLocalQuestionId, payload: null })
    let {
      outcome,
      question,
      savedQuestions: { data: savedQuestions },
    } = getState()
    if (action.payload) {
      const questionId = action.payload
      const questionObj = savedQuestions.find((q) => q.id === questionId)
      if (!questionObj) {
        dispatch({ type: actions.setError, payload: 'common.genericError' })
      } else {
        dispatch({ type: actions.askAgain, payload: questionId })
        question = questionObj.original_description
        outcome = questionObj.result_type
      }
    }
    const response = await nlqApi.textToNLQ(question, outcome, cancelToken)
    if (response.error) {
      handleNLQError(response, dispatch)
      return
    }

    const { query_id, num_results, data, query, parameters, filters } = response.data
    dispatch({
      type: actions.setAnswer,
      payload: {
        query_id,
        num_results,
        data,
        query,
        parameters,
        filters,
      },
    })

    const dataAction = { type: actions.dataFromNLQAsync, payload: { query_id, data } }
    await dataFromNLQAsync({ dispatch, getState })(dataAction)
  }
}

function askAgainAsync({ dispatch, getState }) {
  return async (action) => {
    dispatch({ type: actions.setLoading, payload: true })
    dispatch({ type: actions.setLocalQuestionId, payload: null })
    dispatch({ type: actions.setQuestionChanged, payload: false })
    let {
      localQuestionId,
      savedQuestions: { data: savedQuestions },
    } = getState()
    const { queryId, cancelToken } = action.payload
    const questionObj = savedQuestions.find((q) => q.id === queryId)
    if (questionObj && localQuestionId !== queryId) {
      dispatch({ type: actions.askAgain, payload: queryId })
    }

    const response = await nlqApi.askAgainAsync(queryId, cancelToken)
    if (response.error) {
      handleNLQError(response, dispatch)
      return
    }

    const { query_id, num_results, data, query, parameters, filters } = response.data
    dispatch({
      type: actions.setAnswer,
      payload: {
        query_id,
        num_results,
        data,
        query,
        parameters,
        filters,
      },
    })

    const dataAction = { type: actions.dataFromNLQAsync, payload: { query_id, data } }
    await dataFromNLQAsync({ dispatch, getState })(dataAction)
  }
}

function handleNLQError(response, dispatch) {
  if (response.status === null && response.error.includes('Request canceled by user')) {
    dispatch({ type: actions.setAbortRequestTip, payload: 'Sentinel.errors.abortTip' })
  }
  if (response.status === 500) {
    dispatch({ type: actions.setError, payload: 'Sentinel.errors.nlqError' })
  } else if (response.status === 400) {
    if (response.error.response.data.message.includes('A non-reading operation was attempted')) {
      dispatch({ type: actions.setError, payload: 'Sentinel.errors.attemptToWriteDetected' })
    } else if (
      response.error.response.data.message.includes(
        'Information from a different client was requested',
      )
    ) {
      dispatch({ type: actions.setError, payload: 'Sentinel.errors.attemptToAccessOthersData' })
    } else {
      dispatch({ type: actions.setError, payload: 'Sentinel.errors.nlqError' })
    }
  } else {
    handleError(dispatch, response)
  }
}

function dataFromNLQAsync({ dispatch, getState }) {
  return async (action) => {
    const { page = 1, pageSize = 50, query_id: payloadQueryId, data: payloadData } = action.payload
    const { answer } = getState()
    const { query_id: stateQueryId, data: stateData } = answer || {}
    const queryId = payloadQueryId ?? stateQueryId
    const data = payloadData ?? stateData

    dispatch({ type: actions.setLoading, payload: true })
    if (data.length > 0) {
      const response = await nlqApi.dataFromNLQ(queryId, data, page, pageSize)
      if (response.error) {
        dispatch({ type: actions.setError, payload: 'Sentinel.errors.dataRetrievalError' })
        return
      }

      dispatch({
        type: actions.setResults,
        payload: { page, data: response.data.data, aggregations: response.data.aggregations },
      })
    } else {
      dispatch({ type: actions.setResults, payload: { page, data: [] } })
    }
  }
}

export default function SentinelProvider({ children }) {
  const [state, dispatch] = useImmerReducerAsync(reducer, initialState, asyncActionHandlers)

  const setQuestion = useCallback(
    (question) => {
      dispatch({ type: actions.setQuestion, payload: question })
    },
    [dispatch],
  )

  const setOutcome = useCallback(
    (outcome) => {
      dispatch({ type: actions.setOutcome, payload: outcome })
    },
    [dispatch],
  )

  const saveQuestion = useCallback(
    (queryId, name, callback = () => {}) => {
      const action = { type: actions.saveQuestionAsync, payload: { queryId, name } }
      saveQuestionAsync({ dispatch })(action).then(callback)
    },
    [dispatch],
  )

  const deleteQuestion = useCallback(
    (queryId, name, callback = () => {}) => {
      const action = { type: actions.saveQuestionAsync, payload: { queryId, name, save: false } }
      saveQuestionAsync({ dispatch })(action).then(callback)
    },
    [dispatch],
  )

  const fetchSavedQuestions = useCallback(() => {
    dispatch({ type: actions.fetchQuestionAsync })
  }, [dispatch])

  const askAgain = useCallback(
    (queryId, cancelToken) => {
      const action = { type: actions.askAgainAsync, payload: { queryId, cancelToken } }
      askAgainAsync({ dispatch, getState: () => state })(action)
    },
    [dispatch, state],
  )

  const dataFromNLQ = useCallback(
    (page, pageSize = 50) => {
      const action = { type: actions.dataFromNLQAsync, payload: { page, pageSize } }
      dataFromNLQAsync({ dispatch, getState: () => state })(action)
    },
    [dispatch, state],
  )

  const isQuestionSaved = useCallback(
    (id) => {
      // FIXME: What's the input here? An id?
      return state.savedQuestions.indexOf(id) > -1
    },
    [state.savedQuestions],
  )

  const textToNLQ = useCallback(
    (cancelToken) => {
      dispatch({ type: actions.textToNLQAsync, cancelToken })
    },
    [dispatch],
  )

  const exportToCSV = useCallback(
    (queryId, callback = () => {}) => {
      const ids = state.selectedIds.size > 0 ? Array.from(state.selectedIds) : state.answer.data
      const action = { type: actions.exportToCSVAsync, payload: { queryId, ids } }
      exportToCSVAsync({ dispatch })(action).then(callback)
    },
    [dispatch, state.selectedIds, state.answer],
  )

  const exportToExcel = useCallback(
    (queryId, callback = () => {}) => {
      const ids = state.selectedIds.size > 0 ? Array.from(state.selectedIds) : state.answer.data
      const action = { type: actions.exportToExcelAsync, payload: { queryId, ids } }
      exportToExcelAsync({ dispatch })(action).then(callback)
    },
    [dispatch, state.selectedIds, state.answer],
  )

  const setSelectedIds = useCallback(
    (ids) => {
      dispatch({ type: actions.setSelectedIds, payload: ids })
    },
    [dispatch],
  )

  const setAggregationType = useCallback(
    (type) => {
      dispatch({ type: actions.setAggregationType, payload: type })
    },
    [dispatch],
  )

  return (
    <SentinelContext.Provider value={state}>
      <SentinelDispatchContext.Provider
        value={{
          dispatch,
          setOutcome,
          setQuestion,
          saveQuestion,
          textToNLQ,
          fetchSavedQuestions,
          isQuestionSaved,
          deleteQuestion,
          askAgain,
          exportToCSV,
          exportToExcel,
          setSelectedIds,
          dataFromNLQ,
          setAggregationType,
        }}
      >
        {children}
      </SentinelDispatchContext.Provider>
    </SentinelContext.Provider>
  )
}

SentinelProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useSentinelContext() {
  const state = useContext(SentinelContext)

  if (state === undefined) {
    throw new Error('useSentinelContext must be used within a SentinelProvider')
  }

  return state
}

export function useSentinelDispatchContext() {
  const context = useContext(SentinelDispatchContext)

  if (context === undefined) {
    throw new Error('useSentinelDispatchContext must be used within a SentinelDispatchProvider')
  }

  return context
}

function handleError(dispatch, { error, status, message, data }) {
  const errorCode = codifyAPIError({ error, status, message, data })
  dispatch({ type: actions.setError, errorCode })
}
